"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StellaSwapV4Provider = void 0;
const chain_1 = require("@zenlink-interface/chain");
const AlgebraBase_1 = require("./AlgebraBase");
const LiquidityProvider_1 = require("./LiquidityProvider");
class StellaSwapV4Provider extends AlgebraBase_1.AlgebraBaseProvider {
    constructor(chainId, client) {
        const factory = {
            [chain_1.ParachainId.MOONBEAM]: '0x90dD87C994959A36d725bB98F9008B0b3C3504A0',
        };
        const stateMultiCall = {
            [chain_1.ParachainId.MOONBEAM]: '0x5dA55ED94De76E82A4355921504A7eCFEFA2EF00',
        };
        super(chainId, client, factory, stateMultiCall);
    }
    getType() {
        return LiquidityProvider_1.LiquidityProviders.StellaSwapV4;
    }
    getPoolProviderName() {
        return 'StellaSwapV4';
    }
}
exports.StellaSwapV4Provider = StellaSwapV4Provider;
