"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimalImplLiquidityProvider = exports.LiquidityProvider = exports.LiquidityProviders = void 0;
var LiquidityProviders;
(function (LiquidityProviders) {
    LiquidityProviders["MinimalImpl"] = "MinimalImpl";
    LiquidityProviders["NativeWrap"] = "NativeWrap";
    LiquidityProviders["Zenlink"] = "Zenlink";
    LiquidityProviders["ZenlinkStableSwap"] = "ZenlinkStableSwap";
    LiquidityProviders["StellaSwapV2"] = "StellaSwapV2";
    LiquidityProviders["StellaSwapV3"] = "StellaSwapV3";
    LiquidityProviders["StellaSwapV4"] = "StellaSwapV4";
    LiquidityProviders["StellaStable"] = "StellaStable";
    LiquidityProviders["Solarbeam"] = "Solarbeam";
    LiquidityProviders["ArthSwapV2"] = "ArthSwapV2";
    LiquidityProviders["ArthSwapV3"] = "ArthSwapV3";
    LiquidityProviders["Sirius"] = "Sirius";
    LiquidityProviders["UniswapV2"] = "UniswapV2";
    LiquidityProviders["UniswapV3"] = "UniswapV3";
    LiquidityProviders["GMX"] = "GMX";
    LiquidityProviders["SushiSwap"] = "SushiSwap";
    LiquidityProviders["SushiSwapV3"] = "SushiSwapV3";
    LiquidityProviders["TraderJoeV2"] = "TraderJoeV2";
    LiquidityProviders["ZyberswapV3"] = "ZyberswapV3";
    LiquidityProviders["Curve"] = "Curve";
    LiquidityProviders["BeamswapV2"] = "BeamswapV2";
    LiquidityProviders["BeamswapV3"] = "BeamswapV3";
    LiquidityProviders["BeamStable"] = "BeamStable";
    LiquidityProviders["Beamex"] = "Beamex";
    LiquidityProviders["Izumiswap"] = "Izumiswap";
    LiquidityProviders["DODOV2"] = "DODOV2";
    LiquidityProviders["Syncswap"] = "Syncswap";
    LiquidityProviders["BaseSwap"] = "BaseSwap";
    LiquidityProviders["Aerodrome"] = "Aerodrome";
    LiquidityProviders["AerodromeCL"] = "AerodromeCL";
    LiquidityProviders["Skydrome"] = "Skydrome";
    LiquidityProviders["KyperElastic"] = "KyperElastic";
})(LiquidityProviders || (exports.LiquidityProviders = LiquidityProviders = {}));
class LiquidityProvider {
    constructor(chainId, client) {
        this.stateId = 0;
        this.lastUpdateBlock = 0;
        this.chainId = chainId;
        this.client = client;
    }
    // If pools data were changed then stateId should be increased
    getCurrentPoolStateId() {
        return this.stateId;
    }
    // returns the last processed block number
    getLastUpdateBlock() {
        return this.lastUpdateBlock;
    }
}
exports.LiquidityProvider = LiquidityProvider;
class MinimalImplLiquidityProvider extends LiquidityProvider {
    constructor(chainId, client) {
        super(chainId, client);
    }
    async fetchPoolsForToken(_t0, _t1) {
        await Promise.resolve();
    }
    getCurrentPoolList() {
        return [];
    }
    startFetchPoolsData() { }
    stopFetchPoolsData() { }
    getType() {
        return LiquidityProviders.MinimalImpl;
    }
    getPoolProviderName() {
        return 'MinimalImpl';
    }
}
exports.MinimalImplLiquidityProvider = MinimalImplLiquidityProvider;
