"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LIQUIDITY_PROVIDERS = exports.LIQUIDITY_PROVIDERS_MAP = void 0;
const liquidity_providers_1 = require("../liquidity-providers");
exports.LIQUIDITY_PROVIDERS_MAP = {
    [liquidity_providers_1.LiquidityProviders.Zenlink]: liquidity_providers_1.ZenlinkProvider,
    [liquidity_providers_1.LiquidityProviders.ZenlinkStableSwap]: liquidity_providers_1.ZenlinkStableSwapProvider,
    [liquidity_providers_1.LiquidityProviders.ArthSwapV2]: liquidity_providers_1.ArthSwapProvider,
    [liquidity_providers_1.LiquidityProviders.ArthSwapV3]: liquidity_providers_1.ArthSwapV3Provider,
    [liquidity_providers_1.LiquidityProviders.Sirius]: liquidity_providers_1.SiriusProvider,
    [liquidity_providers_1.LiquidityProviders.GMX]: liquidity_providers_1.GmxProvider,
    [liquidity_providers_1.LiquidityProviders.UniswapV2]: liquidity_providers_1.UniswapV2Provider,
    [liquidity_providers_1.LiquidityProviders.UniswapV3]: liquidity_providers_1.UniswapV3Provider,
    [liquidity_providers_1.LiquidityProviders.SushiSwap]: liquidity_providers_1.SushiProvider,
    [liquidity_providers_1.LiquidityProviders.SushiSwapV3]: liquidity_providers_1.SushiV3Provider,
    [liquidity_providers_1.LiquidityProviders.TraderJoeV2]: liquidity_providers_1.TraderJoeV2Provider,
    [liquidity_providers_1.LiquidityProviders.ZyberswapV3]: liquidity_providers_1.ZyberSwapV3Provider,
    [liquidity_providers_1.LiquidityProviders.Curve]: liquidity_providers_1.CurveStableProvider,
    [liquidity_providers_1.LiquidityProviders.StellaStable]: liquidity_providers_1.StellaStableProvider,
    [liquidity_providers_1.LiquidityProviders.StellaSwapV2]: liquidity_providers_1.StellaSwapV2Provider,
    [liquidity_providers_1.LiquidityProviders.StellaSwapV3]: liquidity_providers_1.StellaSwapV3Provider,
    [liquidity_providers_1.LiquidityProviders.StellaSwapV4]: liquidity_providers_1.StellaSwapV4Provider,
    [liquidity_providers_1.LiquidityProviders.BeamswapV2]: liquidity_providers_1.BeamSwapV2Provider,
    [liquidity_providers_1.LiquidityProviders.BeamswapV3]: liquidity_providers_1.BeamSwapV3Provider,
    [liquidity_providers_1.LiquidityProviders.BeamStable]: liquidity_providers_1.BeamStableProvider,
    [liquidity_providers_1.LiquidityProviders.Beamex]: liquidity_providers_1.BeamexProvider,
    [liquidity_providers_1.LiquidityProviders.Izumiswap]: liquidity_providers_1.IZumiSwapProvider,
    [liquidity_providers_1.LiquidityProviders.DODOV2]: liquidity_providers_1.DodoV2Provider,
    [liquidity_providers_1.LiquidityProviders.Syncswap]: liquidity_providers_1.SyncswapProvider,
    [liquidity_providers_1.LiquidityProviders.BaseSwap]: liquidity_providers_1.BaseSwapProvider,
    [liquidity_providers_1.LiquidityProviders.Aerodrome]: liquidity_providers_1.AerodromeProvider,
    [liquidity_providers_1.LiquidityProviders.AerodromeCL]: liquidity_providers_1.AerodromeCLProvider,
    [liquidity_providers_1.LiquidityProviders.Skydrome]: liquidity_providers_1.SkydromeProvider,
    // [LiquidityProviders.KyperElastic]: KyperElasticProvider,
};
exports.LIQUIDITY_PROVIDERS = [
    liquidity_providers_1.LiquidityProviders.Zenlink,
    liquidity_providers_1.LiquidityProviders.ZenlinkStableSwap,
    liquidity_providers_1.LiquidityProviders.ArthSwapV2,
    liquidity_providers_1.LiquidityProviders.ArthSwapV3,
    liquidity_providers_1.LiquidityProviders.Sirius,
    liquidity_providers_1.LiquidityProviders.GMX,
    liquidity_providers_1.LiquidityProviders.UniswapV2,
    liquidity_providers_1.LiquidityProviders.UniswapV3,
    liquidity_providers_1.LiquidityProviders.SushiSwap,
    liquidity_providers_1.LiquidityProviders.SushiSwapV3,
    liquidity_providers_1.LiquidityProviders.TraderJoeV2,
    liquidity_providers_1.LiquidityProviders.ZyberswapV3,
    liquidity_providers_1.LiquidityProviders.Curve,
    liquidity_providers_1.LiquidityProviders.StellaStable,
    liquidity_providers_1.LiquidityProviders.StellaSwapV2,
    liquidity_providers_1.LiquidityProviders.StellaSwapV3,
    liquidity_providers_1.LiquidityProviders.StellaSwapV4,
    liquidity_providers_1.LiquidityProviders.BeamswapV2,
    liquidity_providers_1.LiquidityProviders.BeamswapV3,
    liquidity_providers_1.LiquidityProviders.BeamStable,
    liquidity_providers_1.LiquidityProviders.Beamex,
    liquidity_providers_1.LiquidityProviders.Izumiswap,
    liquidity_providers_1.LiquidityProviders.DODOV2,
    liquidity_providers_1.LiquidityProviders.Syncswap,
    liquidity_providers_1.LiquidityProviders.BaseSwap,
    liquidity_providers_1.LiquidityProviders.Aerodrome,
    liquidity_providers_1.LiquidityProviders.AerodromeCL,
    liquidity_providers_1.LiquidityProviders.Skydrome,
    // LiquidityProviders.KyperElastic,
];
